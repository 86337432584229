<template>
    <div>
        <el-drawer :with-header="false" size="40%" :visible.sync="dialogDetails" direction="rtl"
            :before-close="handleClose">
            <div class="gl_drawer">
                <!-- 顶部头像 -->
                <div class="gl_drawer_logo flex_between">
                    <div class="flex_center_x">
                        <div class="logo">
                            <img src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg" alt="">
                        </div>
                        <div class="title">{{ dataInfo.name }}</div>
                    </div>

                    <div class="flex_center_x">
                        <div class="gl_button_on flex_center">解散</div>
                        <el-dropdown @command="onClickDropdown">
                            <el-button class="gl_button_to" type="primary">
                                余额<i class="el-icon-arrow-down el-icon--right"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item :command="1">立即调整</el-dropdown-item>
                                <el-dropdown-item :command="2">定时调整</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                        <div @click="onClickGive" class="gl_button_th flex_center">赠送会员</div>
                    </div>
                </div>
                <!-- 资金变动 -->
                <div class="gl_balance_view flex_center_x">
                    <div class="ul flex_column" style="width: 140px;">
                        <div class="li flex_end">
                            <div class="title">账户余额</div>
                        </div>
                        <div class="money">{{ dataInfo.balance || '0.00' }}元</div>
                    </div>
                    <div class="ul flex_column">
                        <div class="li flex_end">
                            <div class="title">赠送余额</div>
                            <div v-show="dataInfo.expireDate" class="text">{{ dataInfo.expireDate }}清零</div>
                        </div>
                        <div class="money">{{ dataInfo.giftBalance || '0.00' }}元</div>
                    </div>
                    <div class="ul flex_column">
                        <!-- <div class=" li flex_center_x">
                            <div class="solid"></div>
                            <div class="title">L{{ dataInfo.memberLevel || '1' }} 会员</div>
                        </div> -->
                        <div class="member_icon" v-if="dataInfo.levelName">
                            <img src="../../assets/memberIcon.png" alt="">
                            <div class="member_icon_title">{{ dataInfo.levelName }} 会员</div>
                        </div>
                        <div class=" li flex_center_x" v-if="dataInfo.endDate">
                            <div class="text">有效期至{{ dataInfo.endDate }}</div>
                            <div class="tips">查看权益</div>
                        </div>
                    </div>
                </div>
                <!-- 调整余额组件 -->
                <teamListDetailsBalance @submitFormBalance="submitFormBalance" @handleCloseBalance="handleCloseBalance"
                    :balanceNum=balanceNum ref="teamListDetailsBalance" :dialogVisibleBalance="dialogVisibleBalance"
                    :detailsData="detailsData">
                </teamListDetailsBalance>

                <!-- 赠送会员组件 -->
                <teamListDetailsGive ref="teamListDetailsGive" @submitFormEdit="submitFormGive"
                    @editingClose="editingCloseDetailsGive" :dialogGive="dialogGive" :detailsData="detailsData">
                </teamListDetailsGive>
                <!-- tabs -->
                <div class="gl_tabs">
                    <el-tabs v-model="activeName" @tab-click="handleClickTabs">
                        <el-tab-pane name="first" label="团队资料">
                            <div class="gl_tabs_view">
                                <!-- 顶部标题 -->
                                <div class="gl_title flex_center_x" style="margin-bottom: 2px;">
                                    <div class="radius"></div>
                                    <div class="title">基本信息</div>
                                </div>
                                <!-- 内容 -->
                                <div class="gl_info flex_center_x">
                                    <div class=" gl_info_ul flex_center_x">
                                        <div class="title">团队编号：</div>
                                        <div class="text">ajinm123</div>
                                    </div>
                                    <div class=" gl_info_ul flex_center_x">
                                        <div class="title">团队名称：</div>
                                        <div class="text">{{ dataInfo.name }}</div>
                                    </div>
                                </div>
                                <div class="gl_info flex_center_x">
                                    <div class=" gl_info_ul flex_center_x">
                                        <div class="title">所有人：</div>
                                        <div class="text">{{ dataInfo.phone }}</div>
                                    </div>
                                </div>
                                <!-- 分割线 -->
                                <div class="gl_solid">
                                    <el-divider></el-divider>
                                </div>
                                <!-- 顶部标题 -->
                                <div class="gl_title flex_center_x" style="margin-bottom: 2px;">
                                    <div class="radius"></div>
                                    <div class="title">团队概况</div>
                                </div>
                                <!-- 内容 -->
                                <div class="gl_info flex_center_x">
                                    <div class=" gl_info_ul flex_center_x">
                                        <div class="title">成员数量：</div>
                                        <div class="text">{{ dataInfo.childNum }}</div>
                                    </div>
                                    <div class=" gl_info_ul flex_center_x">
                                        <div class="title">团队状态：</div>
                                        <div class="text">{{ dataInfo.status == 1 ? '开启' : '关闭' }}</div>
                                    </div>
                                </div>
                                <div class="gl_info flex_center_x">
                                    <div class=" gl_info_ul flex_center_x">
                                        <div class="title">分组：</div>
                                        <div class="text">{{ dataInfo.groupName }}</div>
                                    </div>
                                    <div class=" gl_info_ul flex_center_x">
                                        <div class="title">标签：</div>
                                        <div class="text">{{ dataInfo.labels }}</div>
                                    </div>
                                </div>
                                <div class="gl_info flex_center_x">
                                    <div class=" gl_info_ul flex_center_x">
                                        <div class="title">创建时间：</div>
                                        <div class="text">{{ detailsData.createdTime }}</div>
                                    </div>
                                    <!-- <div class=" gl_info_ul flex_center_x">
                                        <div class="title">登录时间：</div>
                                        <div class="text">ajinm123</div>
                                    </div> -->
                                </div>
                                <!-- 分割线 -->
                                <div class="gl_solid">
                                    <el-divider></el-divider>
                                </div>
                                <!-- 顶部标题 -->
                                <div class="gl_title flex_center_x" style="margin-bottom: 2px;">
                                    <div class="radius"></div>
                                    <div class="title">备注</div>
                                </div>
                                <!-- 内容 -->
                                <div class="gl_info flex_center_x">
                                    <div class=" gl_info_ul flex_center_x">
                                        <div class="title">备注：</div>
                                        <div class="text">{{ dataInfo.remark || '-' }}</div>
                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane name="second" label="主体信息">
                            <!-- 存在 -->
                            <div class="gl_tabs_view" v-if="detailsData.type == 1">
                                <div class="gl_view_z flex_center_x">
                                    <div class="gl_title_z">认证类型：</div>
                                    <div class="gl_text_z">企业</div>
                                </div>
                                <div class="gl_view_z flex_center_x">
                                    <div class="gl_title_z">企业名称：</div>
                                    <div class="gl_text_z">{{ ruleForm.name }}</div>
                                </div>
                                <div class="gl_view_z flex_center_x">
                                    <div class="gl_title_z">统一社会信用代码：</div>
                                    <div class="gl_text_z">{{ ruleForm.certificateNo }}</div>
                                </div>
                                <div class="gl_view_z flex_center_x">
                                    <div class="gl_title_z">法定代表人：</div>
                                    <div class="gl_text_z">{{ ruleForm.legalPersonName }}</div>
                                </div>
                                <div class="gl_view_z flex_center_x">
                                    <div class="gl_title_z">注册资本：</div>
                                    <div class="gl_text_z">{{ ruleForm.capital }}万元</div>
                                </div>
                                <div class="gl_view_z flex_center_x">
                                    <div class="gl_title_z">成立日期：</div>
                                    <div class="gl_text_z">{{ ruleForm.foundTime }}</div>
                                </div>
                                <div class="gl_view_z flex_center_x">
                                    <div class="gl_title_z">住所：</div>
                                    <div class="gl_text_z">{{ ruleForm.residence }}</div>
                                </div>
                                <div class="gl_view_z flex_center_x">
                                    <div class="gl_title_z">经营范围：</div>
                                    <div class="gl_text_z">{{ ruleForm.businessScope }}</div>
                                </div>
                            </div>

                            <div class="gl_tabs_view" v-if="detailsData.type == 2">
                                <div class="gl_view_z flex_center_x">
                                    <div class="gl_title_zh">认证类型：</div>
                                    <div class="gl_text_z">个人</div>
                                </div>
                                <div class="gl_view_z flex_center_x">
                                    <div class="gl_title_zh">姓名：</div>
                                    <div class="gl_text_z">{{ ruleForm.name }}</div>
                                </div>
                                <div class="gl_view_z flex_center_x">
                                    <div class="gl_title_zh">证件号码：</div>
                                    <div class="gl_text_z">{{ ruleForm.certificateNo }}</div>
                                </div>
                                <div class="gl_view_z flex_center_x">
                                    <div class="gl_title_zh">性别：</div>
                                    <div class="gl_text_z">{{ ruleForm.sex == 2 ? '女' : '男' }}</div>
                                </div>
                                <div class="gl_view_z flex_center_x">
                                    <div class="gl_title_zh">民族：</div>
                                    <div class="gl_text_z">{{ ruleForm.nation }}</div>
                                </div>
                                <div class="gl_view_z flex_center_x">
                                    <div class="gl_title_zh">出生日期：</div>
                                    <div class="gl_text_z">{{ ruleForm.birth }}</div>
                                </div>
                                <div class="gl_view_z flex_center_x">
                                    <div class="gl_title_zh">住所：</div>
                                    <div class="gl_text_z">{{ ruleForm.address }}</div>
                                </div>
                            </div>
                            <div class="gl_tabs_view" v-if="detailsData.type == 0">
                                <div class="information">
                                    <img src="../../assets/null_ep.png" alt="">
                                    <div class="information_title">暂无信息哦， 快去 <span @click="onClickInformation"
                                            style="color: #1883E3;cursor: pointer;">实名认证</span> 吧～
                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane name="third" label="资金明细">
                            <div class="gl_tabs_view_table">
                                <el-table :data="tableDataFund" style="width: 100%" :cell-style="{ 'text-align': 'center' }"
                                    :header-cell-style="{ background: '#B5DFFF', color: '#333333', 'text-align': 'center' }">
                                    <el-table-column prop="name" fixed="left" label="动作" width="70"></el-table-column>
                                    <el-table-column prop="name" label="资金账户"></el-table-column>
                                    <el-table-column prop="name" label="变动金额" width="80"></el-table-column>
                                    <el-table-column prop="name" label="余额" width="70"></el-table-column>
                                    <el-table-column prop="name" label="操作用户" width="100"></el-table-column>
                                    <el-table-column prop="name" label="创建时间" width="150"></el-table-column>
                                    <el-table-column prop="name" label="备注" width="150"></el-table-column>
                                </el-table>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane name="fourth" label="成员帐号">
                            <div class="gl_tabs_view_table">
                                <div @click="onClickUser" class="gl_tabs_button flex_center">添加成员</div>
                                <el-table :data="tableDataTeam" style="width: 100%" :cell-style="{ 'text-align': 'center' }"
                                    :header-cell-style="{ background: '#B5DFFF', color: '#333333', 'text-align': 'center' }">
                                    <el-table-column prop="userName" fixed="left" label="姓名" width="100"></el-table-column>
                                    <el-table-column prop="phone" label="手机号" width="140"></el-table-column>
                                    <el-table-column prop="position" label="身份" width="80"></el-table-column>
                                    <el-table-column prop="createTime" label="创建时间" width="200"></el-table-column>
                                    <el-table-column prop="updateTime" label="最后编辑时间" width="200"></el-table-column>
                                    <el-table-column prop="name" label="操作" fixed="right" width="150">
                                        <template slot-scope="scope">
                                            <el-button @click="handleClick(scope.row)" type="text">功能权限</el-button>
                                            <el-button type="text">删除</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>

                                <div class="gl_pagination">
                                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                                        :current-page="pages.pageNumber" :page-sizes="[3, 4, 5]" :page-size="pages.pageSize"
                                        layout="total, sizes, prev, pager, next, jumper" :total="total">
                                    </el-pagination>
                                </div>
                                <teamListEdit @submitFormEdit="submitFormEdit" @editingClose="editingClose"
                                    ref="teamListEdit" :detailsData="detailsData" :dialogFunction="dialogFunction"
                                    :dialogFunctionData="dialogFunctionData">
                                </teamListEdit>
                                <teamlistDetailsAdd :detailsData="detailsData" :dialogVisible="dialogVisible"
                                    @submitForm="submitForm" @handleClose="handleCloseAdd"> </teamlistDetailsAdd>
                            </div>
                        </el-tab-pane>
                        <!-- <el-tab-pane label="角色管理">
                        </el-tab-pane> -->
                    </el-tabs>
                </div>
            </div>
        </el-drawer>
    </div>
</template>
<script>
import teamListEdit from "@/views/teamManagement/teamListEdit.vue"
import teamlistDetailsAdd from "@/views/teamManagement/teamlistDetailsAdd.vue"
import teamListDetailsBalance from "@/views/teamManagement/teamListDetailsBalance.vue"
import teamListDetailsGive from "@/views/teamManagement/teamListDetailsGive.vue"
// import teamListEditing from "@/views/teamManagement/teamListEditing.vue"

export default {
    props: ["dialogDetails", "detailsData"],
    components: {
        teamListEdit,
        teamlistDetailsAdd,
        teamListDetailsBalance,
        teamListDetailsGive
    },
    data() {
        return {
            activeName: 'first',
            // 功能权限对象
            dialogFunction: false,
            dialogFunctionData: {},
            // 资金
            tableDataFund: [],
            // 团队
            pages: {
                pageNumber: 1,
                pageSize: 5,
            },
            total: 0,
            tableDataTeam: [],
            dialogVisible: false,
            //   基本信息
            dataInfo: {},
            // 调整余额
            dialogVisibleBalance: false,
            balanceNum: 1,
            // 赠送会员
            dialogGive: false,
            ruleForm: {},//主体信息
        }
    },
    created() {

    },
    mouted() {

    },
    methods: {
        // 切换顶部tabs
        handleClickTabs(tab, event) {
            // 点击顶部 请求成员账号
            if (this.activeName == 'fourth') {
                this.pages = {
                    pageNumber: 1,
                    pageSize: 5,
                    memberId: this.detailsData.id
                };
                this.getList(true);
            }
            // 点击顶部，请求资金明细
            if (this.activeName == 'third') {
                this.pages = {
                    pageNumber: 1,
                    pageSize: 5,
                    memberId: this.detailsData.id
                };
                this.getListFund(true);
            }
            // 点击顶部，请求主体信息
            if (this.activeName == 'second') {
                this.getInformation(true);
            }
        },
        // 点击调整余额
        onClickDropdown(command) {
            this.balanceNum = command;
            this.dialogVisibleBalance = true;
            // 
            this.$refs.teamListDetailsBalance.callMethod(this.dataInfo)

        },
        // 关闭调整余额弹窗
        handleCloseBalance(msg) {
            this.dialogVisibleBalance = msg;
        },
        // 成功调整余额
        submitFormBalance() {
            this.handleCloseBalance(false)
            this.callMethod(this.detailsData.id)
        },
        // 关闭弹窗
        handleClose() {
            this.$emit('detailsClose', false);
        },
        // 点击赠送会员
        onClickGive() {
            this.dialogGive = true;
            this.$refs.teamListDetailsGive.callMethod(this.detailsData.id)
        },
        // 关闭赠送会员
        editingCloseDetailsGive(msg) {
            this.dialogGive = msg;
        },
        // 赠送会员 成功按钮
        submitFormGive() {
            this.dialogGive = false;
            this.callMethod(this.detailsData.id);
        },
        // 成员账号点击 功能授权
        handleClick(data) {
            this.dialogFunction = true;
            this.dialogFunctionData = data;
            this.$refs.teamListEdit.callMethod(this.detailsData.id, data.id)
        },
        // 成员账号关闭 功能授权
        editingClose(msg) {
            this.dialogFunction = msg;
        },
        // 成员账号 功能授权 确认
        submitFormEdit() {
            this.editingClose(false)
            this.getList(false);
        },
        // 添加成员
        onClickUser() {
            console.log(this.detailsData)
            this.dialogVisible = true;
        },
        // 关闭添加成员
        handleCloseAdd(msg) {
            this.dialogVisible = msg;
        },
        // 添加成员完成
        submitForm() {
            this.dialogVisible = false;
            this.getList(false);
        },
        // 点击实名认证
        onClickInformation() {
            this.handleClose();//关闭弹窗
            this.$emit('Editing', this.detailsData);
        },
        // 团队成员 分页
        handleSizeChange(val) {
            this.pages.pageSize = val;
            this.getList(true);
        },
        handleCurrentChange(val) {
            this.pages.pageNumber = val;
            this.getList(true);
        },
        // 获取团队列表
        async getList(type) {
            const { data: res } = await this.$http({
                url: "/member/getMemberUserList",
                method: "post",
                data: this.pages
            });
            this.total = res.content.count
            this.tableDataTeam = res.content.items
            if (type) {
                this.$message.success("查询成功")
            }
        },
        // 获取资金列表
        async getListFund(type) {
            const { data: res } = await this.$http({
                url: "/member/getFundRecordList",
                method: "post",
                data: this.pages
            });
            this.total = res.content.count
            this.tableDataFund = res.content.items
            if (type) {
                this.$message.success("查询成功")
            }
        },
        // 获取主体信息接口
        async getInformation() {
            if (this.detailsData.type) {
                // 获取认证 信息
                const { data: res } = await this.$http({
                    url: "/member/getAuthentication",
                    method: "post",
                    data: { memberId: this.detailsData.id }
                });
                this.ruleForm = res.content
            }
        },
        // 获取基本信息
        async callMethod(id) {
            var { data: res } = await this.$http({
                url: "/member/getDetail",
                method: "post",
                data: { memberId: id }
            });

            if (res.content.expireDate) {
                res.content.expireDate = res.content.expireDate.substr(0, res.content.expireDate.length - 9)
            }
            if (res.content.endDate) {
                res.content.endDate = res.content.endDate.substr(0, res.content.endDate.length - 9)
            }
            this.dataInfo = res.content
            this.activeName = 'first';
        },

    },
}
</script>
<style scoped>
.gl_drawer {
    box-sizing: border-box;
    padding-top: 60px;
}

.gl_drawer_logo {
    width: 100%;
    height: 52px;
    box-sizing: border-box;
    padding: 0 20px;
}

.gl_drawer_logo .logo {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background: #f1f1f1;
}

.gl_drawer_logo .logo>img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.gl_drawer_logo .title {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
    margin-left: 20px;
}

.gl_drawer_logo .gl_button_th {
    box-sizing: border-box;
    width: 100px;
    height: 30px;
    border: 1px solid #1883E3;
    border-radius: 4px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #1883E3;
    cursor: pointer;
}

.gl_drawer_logo .gl_button_on {
    width: 64px;
    height: 30px;
    background: rgba(232, 232, 232);
    border-radius: 4px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
    opacity: 1;
    margin-right: 20px;
}

.gl_drawer_logo .gl_button_to {
    padding: 0;
    width: 80px;
    height: 30px;
    background: rgba(109, 220, 122);
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    opacity: 1;
    margin-right: 20px;
}

/* 资金 */
.gl_balance_view {
    width: 100%;
    height: 54px;
    box-sizing: border-box;
    padding: 0 20px;
    margin-top: 20px;
}

.gl_balance_view>.ul {
    width: 220px;
    height: 100%;
}

.gl_balance_view>.ul>.money {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    opacity: 1;
}

.gl_balance_view>.ul>.li>.title {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
    opacity: 1;
    margin-right: 4px;
}

.gl_balance_view>.ul>.li>.text {
    font-size: 8px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #E61C1C;
    opacity: 0.66;
}

.gl_balance_view>.ul>.li>.solid {
    width: 22px;
    height: 22px;
    background: rgba(234, 230, 147);
    border-radius: 50%;
    opacity: 1;
    margin-right: 12px;
}

.gl_balance_view>.ul>.li>.tips {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 18px;
    color: #1883E3;
    opacity: 1;
    margin-left: 4px;
}

.gl_tabs {
    width: 100%;
    height: 100%;
    margin-top: 20px;
}


.gl_tabs_view {
    box-sizing: border-box;
    padding-top: 14px;
}

.gl_view_z {
    box-sizing: border-box;
    padding: 0 20px;
    margin-bottom: 16px;
}

.gl_view_z>.gl_title_z {
    min-width: 140px;
    font-size: 14px;
    text-align: right;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
}

.gl_view_z>.gl_title_zh {
    min-width: 72px;
    font-size: 14px;
    text-align: right;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
}

.gl_view_z>.gl_text_z {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
    margin-left: 14px;
}

.gl_tabs_view_table {
    box-sizing: border-box;
    padding: 0 20px;
}

.gl_tabs_button {
    width: 100px;
    height: 34px;
    background: rgba(0, 131, 227);
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    opacity: 1;
    border-radius: 4px;
    margin-bottom: 14px;
    cursor: pointer;
}

.gl_title {
    width: 100%;
    box-sizing: border-box;
    padding-left: 20px;
}

.gl_title>.radius {
    width: 5px;
    height: 5px;
    background: rgba(24, 131, 227);
    border-radius: 50%;
    opacity: 1;
}

.gl_title>.title {
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    margin-left: 6px;
}

.gl_info {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    margin-top: 10px;
}

.gl_info_ul {
    width: 240px;
    height: 22px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
}

.gl_info_ul>.title {
    width: 80px;
    text-align: right;
}

.gl_solid {
    box-sizing: border-box;
    padding: 0 14px;
}

.flex_center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex_center_x {
    display: flex;
    align-items: center;
}

.flex_between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex_column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.flex_end {
    display: flex;
    align-items: flex-end;
}

.gl_pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.member_icon {
    width: 98px;
    height: 26px;
    background: #E5E5E5;
    border-radius: 20px;
    display: flex;
    align-items: center;
}

.member_icon>img {
    width: 24px;
    height: 24px;
    border: 2px solid #fff;
    border-radius: 50%;
}

.member_icon_title {
    width: 60px;
    text-align: center;
    font-size: 14px;
    font-family: PingFang SC;
    color: #666666;
}

.information {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.information>img {
    width: 306px;
    height: 306px;
    margin-top: -40px;
}

.information_title {
    color: #666666;
    margin-top: -40px;
}
</style>

<style>
.gl_tabs .el-tabs--border-card {
    border: none;
    box-shadow: none;


}

.gl_tabs .el-tabs__nav-scroll {
    background: rgba(244, 247, 254);
    height: 48px;
}

.gl_tabs .el-tabs__item {
    width: 108px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    padding: 0;
    font-size: 17px;
    font-family: PingFang SC;
    font-weight: 500;
    opacity: 1;
}

.gl_tabs .el-tabs__item.is-active {
    background: #fff;
}

.gl_tabs .el-tabs__active-bar {
    width: 50px !important;
    left: 30px;
}

.gl_tabs .el-tabs__nav-wrap::after {
    background-color: #fff
}
</style>