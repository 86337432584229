<template>
    <div>
        <el-dialog append-to-body title="添加成员" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="手机号" prop="phone">
                    <el-input v-model="ruleForm.phone"></el-input>
                </el-form-item>
                <el-form-item label="成员身份">
                    <el-select disabled style="width: 100%;" v-model="ruleForm.position" placeholder="请选择活动区域">
                        <el-option label="普通成员" value="普通成员"></el-option>
                        <el-option label="普通成员" value="普通成员"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <div class="gl_button_item">
                        <el-button @click="resetForm('ruleForm')">重置</el-button>
                        <el-button type="primary" @click="submitForm('ruleForm')">立即添加</el-button>
                    </div>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
export default {

    props: ["dialogVisible", "detailsData"],
    data() {
        var validatePhone = (rule, value, callback) => {
            var reg = /^[1][3,4,5,7,8][0-9]{9}$/;
            if (value === '') {
                callback(new Error('请输入手机号'));
            } else if (!reg.test(value)) {
                callback(new Error('手机号输入有误'));
            } else {
                callback();
            }
        };
        return {
            ruleForm: {
                position: "普通成员",
                phone: "",
            },
            rules: {
                phone: [{ required: true, validator: validatePhone, trigger: 'blur' }],
            },
            loading: null,
        }
    },
    created() {

    },
    mouted() {

    },
    methods: {
        // 关闭弹窗
        handleClose() {
            this.$emit('handleClose', false);
        },
        // 重置
        resetForm() {
            this.ruleForm.phone = '';
        },
        // 添加用户
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    this.loading = this.$loading({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    this.ruleForm.memberId = this.detailsData.id
                    var { data: res } = await this.$http({
                        url: "/member/addMemberUser",
                        method: "post",
                        data: this.ruleForm
                    });
                    this.loading.close();
                    if (res.resultStates != 0) return this.$message.error(res.message)
                    this.$message.success("添加成功")

                    this.$emit('submitForm', true);
                    this.resetForm();
                } else {
                    return false
                }
            })
        },
    },
}
</script>
<style scoped >
.gl_button_item {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
</style>