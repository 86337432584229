<template>
    <div>
        <el-dialog append-to-body title="赠送会员等级" :visible.sync="dialogGive" width="60%" :before-close="handleClose">
            <el-form ref="form" :model="form" label-width="100px" :rules="rules">
                <el-form-item label="会员等级" prop="levelId">
                    <el-select v-model="form.levelId" placeholder="请选择活动区域" @change="selectChange">
                        <el-option v-for="item in optionsLevel" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <div class="gl_view" v-show="msgList.length > 0">
                    <div class="gl_flex" v-for="(item, index) in msgList" :key="index">
                        <el-checkbox disabled v-model="item.isSelect">
                            <div class="gl_title">{{ item.name }}</div>
                        </el-checkbox>
                        <el-input disabled size="mini" class="gl_input_view" v-model="item.discount">
                            <template slot="append">折</template>
                        </el-input>

                        <el-checkbox style="margin: 0 20px 0 70px;" v-model="item.isChecked">
                            自定义
                        </el-checkbox>

                        <el-input placeholder="填写金额" v-show="item.isChecked" size="mini" class="gl_input_view"
                            v-model="item.define">
                            <template slot="append">折</template>
                        </el-input>
                    </div>
                </div>
                <el-form-item label="创建时间" prop="startDate">
                    <el-date-picker value-format="yyyy-MM-dd" format="yyyy-MM-dd" v-model="input" type="datetimerange"
                        range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" align="right"
                        @change="onClickPicker">
                    </el-date-picker>
                    <div class="gl_title_tips">开启时间早于当前时间，会员将立即生效</div>
                </el-form-item>
                <el-form-item>
                    <div class="form_item_button">
                        <el-button @click="handleClose">取消</el-button>
                        <el-button type="primary" @click="onSubmit('form')">立即创建</el-button>
                    </div>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
    props: ["dialogGive", "dialogFunctionData", 'detailsData'],
    data() {
        return {
            input: [],
            form: {
                startDate: "",
                endDate: "",
                memberId: "",
                levelId: "",
                levelName: "",
                content: [],
            },
            rules: {
                levelId: [
                    { required: true, message: '请选择会员等级', trigger: 'blur' },
                ],
                startDate: [
                    { required: true, message: '请选择创建时间', trigger: 'blur' },
                ],
            },
            loading: null,
            optionsLevel: {},
            msgList: [],
        }
    },
    created() {

    },
    mouted() {

    },
    methods: {
        // 选择顶部
        async selectChange(data) {
            console.log()
            console.log(data)
            const { data: res } = await this.$http({
                url: "/member/getFuncList",
                method: "post",
                data: { levelId: data }
            });
            this.msgList = res.content
            this.form.levelName = this.optionsLevel[data].label
        },
        // 点击时间选择
        onClickPicker(event) {
            console.log(event)
            if (event == null) {
                this.form.startDate = '';
                this.form.endDate = '';
            } else {
                this.form.startDate = event[0];
                this.form.endDate = event[1];
            }
        },
        // 关闭弹窗
        handleClose() {
            this.$emit('editingClose', false);
        },

        // 提交
        async onSubmit(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    let form = JSON.parse(JSON.stringify(this.form))
                    let msgList = this.msgList;
                    form.memberId = this.detailsData.id
                    for (var a = 0; a < msgList.length; a++) {
                        let item = msgList[a];
                        if (item.isChecked) {
                            if (item.define) {
                                form.content.push({
                                    code: item.price,
                                    price: item.define
                                })
                            } else {
                                return this.$message.error(item.name + " 没有填写自定义值")
                            }
                        }
                    }
                    console.log(form)
                    if (form.startDate) form.startDate = form.startDate + ' 00:00:00';
                    if (form.endDate) form.endDate = form.endDate + ' 23:59:59'
                    this.loading = this.$loading({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });


                    var { data: res } = await this.$http({
                        url: "/member/addMemberLevelRecord",
                        method: "post",
                        data: form
                    });
                    this.loading.close();
                    if (res.resultStates != 0) return this.$message.error(res.message)
                    this.$message.success("修改成功")
                    this.$emit('submitFormEdit', false);
                } else {
                    return false
                }
            })
        },
        // 会员接口
        async getLevelAsync() {
            const { data: res } = await this.$http({
                url: "/member/getLevelList",
                method: "post",
                data: {}
            });
            let optionsLevel = [];
            for (var a = 0; a < res.content.length; a++) {
                optionsLevel.push({
                    value: res.content[a].id,
                    label: res.content[a].name
                })
            }
            this.optionsLevel = optionsLevel;
        },
        async callMethod(memberId) {
            await this.getLevelAsync();
            this.input = [];
            this.form.startDate = '';
            this.form.endDate = '';
        },
    },
}
</script>
<style scoped>
.gl_view {
    width: 100%;
    /* height: 400px; */
    border: 1px solid #CECECE;
    opacity: 1;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 20px 30px;
    margin: 22px 0;
}

.gl_flex {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.gl_flex:last-child {
    margin-bottom: 0;
}

.gl_title {
    min-width: 240px;
    max-width: 240px;
    display: inline-block;
}

.gl_input_view {
    width: 100px;
}

.form_item_button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.gl_title_tips {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #9F9F9F;
    opacity: 1;
}
</style>

<style>
.gl_input_view .el-input__inner {
    height: 30px;
    min-width: 100px;
    max-width: 100px;
}
</style>