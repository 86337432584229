<template>
    <div>
        <el-dialog append-to-body :title="balanceNum == 1 ? '立即调整余额' : '定时调整余额'" :visible.sync="dialogVisibleBalance"
            width="40%" :before-close="handleCloseBalance">
            <el-dialog class="demo-dialogData" title="设置清零日期" :visible.sync="dialogVisible" width="40%"
                :before-close="handleClose" append-to-body>
                <el-form :model="dialogData" status-icon ref="dialogData" label-width="100px" class="demo-dialogData">
                    <el-form-item label="清零日期">
                        <el-date-picker value-format="yyyy-MM-dd" format="yyyy-MM-dd" v-model="dialogData.expireDate"
                            align="right" type="date" placeholder="选择日期" :picker-options="pickerOptions">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <div class="form_item_button">
                            <el-button @click="resetForm">重 置</el-button>
                            <el-button type="primary" @click="submitForm">确 认</el-button>
                        </div>
                    </el-form-item>
                </el-form>
            </el-dialog>

            <el-form :model="dialogData" status-icon ref="dialogData" label-width="100px" class="demo-dialogData">
                <el-form-item label="调整">
                    <el-radio-group v-model="state">
                        <el-radio :label="1">增加</el-radio>
                        <el-radio :label="2">减少</el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="余额">
                    <el-input type="number" placeholder="请输入余额" v-model="dialogData.amount">
                        <template slot="append">元</template></el-input>
                </el-form-item>
                <el-form-item label="调整">
                    <el-radio-group v-model="stateGive">
                        <el-radio :label="1">增加</el-radio>
                        <el-radio :label="2">减少</el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="赠送余额">
                    <el-input type="number" placeholder="请输入赠送余额" v-model="dialogData.giftAmount">
                        <template slot="append">元</template>
                    </el-input>
                    <div>
                        <div v-if="!dialogData.expireDate">尚未创建赠送余额清零日期， <el-button type="text"
                                @click="dialogVisible = true">立即创建</el-button></div>
                        <div v-if="dialogData.expireDate">剩余赠送余额将于{{ dialogData.expireDate }}清零，<el-button type="text"
                                @click="dialogVisible = true">编辑日期</el-button></div>
                    </div>
                </el-form-item>

                <el-form-item v-if="balanceNum == 2" label="调整时间">
                    <el-date-picker value-format="yyyy-MM-dd" format="yyyy-MM-dd" v-model="dialogData.date" align="right"
                        type="date" placeholder="选择调整时间" :picker-options="pickerOptions">
                    </el-date-picker>
                </el-form-item>

                <el-form-item>
                    <div class="form_item_button">
                        <el-button @click="resetFormBalance">重 置</el-button>
                        <el-button type="primary" @click="submitFormBalance">确 认</el-button>
                    </div>
                </el-form-item>
            </el-form>
        </el-dialog>

    </div>
</template>
<script>
export default {
    props: ["dialogVisibleBalance", "detailsData", "balanceNum"],
    data() {
        return {
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now();
                },
            },
            state: 1,
            stateGive: 1,
            dialogData: {},
            dialogVisible: false,
            loading: null,
        }
    },
    created() {

    },
    mouted() {

    },
    methods: {
        // 关闭清零日期弹窗
        handleClose() {
            this.dialogVisible = false;
        },
        // 重置清零日期
        resetForm() {
            this.dialogData.expireDate = "";
        },
        // 确认清零日期
        submitForm() {
            this.dialogVisible = false;
        },
        // 确认修改金额
        async submitFormBalance() {
            let dialogData = JSON.parse(JSON.stringify(this.dialogData))
            console.log(dialogData)
            // 空填写交验
            if (!dialogData.amount && !dialogData.giftAmount) return this.$message.error('请填写金额')
            // 如果存在 赠送余额，需要填写 清零日期
            if (dialogData.giftAmount) {
                if (!dialogData.expireDate) return this.$message.error('请选择清零日期')
            } else {
                dialogData.expireDate = "";
            }
            // 1: 实时调整，2: 定时调整
            if (this.balanceNum == 2) {
                if (!dialogData.date) return this.$message.error('选择调整时间')
                if (dialogData.date && dialogData.expireDate) {
                    const result = this.compareTimeStrings(dialogData.expireDate, dialogData.date)
                    if (!result) return this.$message.error('清零日期需要晚于调整时间')
                }
            }

            dialogData.memberId = this.detailsData.id;
            dialogData.type = this.balanceNum;

            if (this.state == 2 && dialogData.amount) dialogData.amount = '-' + dialogData.amount
            if (this.stateGive == 2 && dialogData.giftAmount) dialogData.giftAmount = '-' + dialogData.giftAmount
            if (dialogData.expireDate) dialogData.expireDate = dialogData.expireDate + ' 00:00:00';
            if (dialogData.date) dialogData.date = dialogData.date + ' 00:00:00';
            this.loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            var { data: res } = await this.$http({
                url: "/member/addAmountRecord",
                method: "post",
                data: dialogData
            });
            this.loading.close();
            if (res.resultStates != 0) return this.$message.error(res.message)
            this.$message.success("修改成功")
            this.resetFormBalance();
            this.$emit('submitFormBalance', true);
        },
        // 重置修改金额
        resetFormBalance() {
            this.dialogData = {};
        },
        // 关闭修改金额弹窗
        handleCloseBalance() {
            this.resetFormBalance();
            this.$emit('handleCloseBalance', false);
        },
        // 配置
        callMethod(data) {
            console.log(data)
            this.$set(this.dialogData, 'expireDate', data.expireDate || "")
        },

        // 比较时间大小
        compareTimeStrings(timeString1, timeString2) {
            const date1 = new Date(timeString1);
            const date2 = new Date(timeString2);

            if (isNaN(date1) || isNaN(date2)) {
                throw new Error("Invalid time string format. Expected format: 'YYYY-MM-DD'");
            }

            if (date1 < date2) {
                return -1;
            } else if (date1 > date2) {
                return 1;
            } else {
                return 0;
            }
        }

    },
}
</script>
<style scoped>
.form_item_button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
</style>