<template>
    <div>
        <el-dialog append-to-body title="功能权限" :visible.sync="dialogFunction" width="34%" :before-close="handleClose">
            <div class="gl_dialog_title">功能关闭后，成员将不可以团队名义使用该功能</div>
            <div class="gl_dialog_text flex_center_x">
                <el-button @click="onclickRight(true)" class="text" type="text">全部开启</el-button>
                <el-button @click="onclickRight(false)" class="text" style="color:#999999" type="text">全部关闭</el-button>
            </div>
            <div class="gl_dialog_switch flex_wrap_between">
                <div class="gl_dialog_li flex_center" v-for="(item, index) in authorityList" :key="index">
                    <div class="title">{{ item.name }}</div>
                    <el-switch v-model="item.isSelect"></el-switch>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">保 存</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: ["dialogFunction", "dialogFunctionData", 'detailsData'],
    data() {
        return {
            authorityList: [],
            loading: null,
        }
    },
    created() {

    },
    mouted() {

    },
    methods: {
        // 全部开启/关闭
        onclickRight(type) {
            let authorityList = this.authorityList
            authorityList.forEach(item => {
                item.isSelect = type
            })
            this.authorityList = authorityList;
        },
        // 关闭弹窗
        handleClose() {
            this.$emit('editingClose', false);
        },
        // 提交
        async submitForm() {
            this.loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let o = {
                memberId: this.detailsData.id,
                id: this.dialogFunctionData.id,
                funcList: [],
            }
            this.authorityList.forEach(item => {
                if (item.isSelect) {
                    o.funcList.push(item.code)
                }
            })
            var { data: res } = await this.$http({
                url: "/member/saveMemberUserFunc",
                method: "post",
                data: o
            });
            this.loading.close();
            if (res.resultStates != 0) return this.$message.error(res.message)
            this.$message.success("修改成功")
            this.$emit('submitFormEdit', false);

        },
        // 成员权限列表
        async callMethod(memberId, id) {
            let o = {
                memberId, id,
            }
            const { data: res } = await this.$http({
                url: "/member/getMemberUserFunc",
                method: "post",
                data: o
            });
            this.authorityList = res.content
        },
    },
}
</script>
<style scoped>
.gl_dialog_title {
    font-size: 17px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
    opacity: 1;
}

.gl_dialog_text {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
    margin-top: 20px;
}

.gl_dialog_text>.text {
    margin-left: 20px;
}

.gl_dialog_switch {
    width: 100%;
}

.gl_dialog_li {
    margin-top: 12px;
    width: 182px;
    height: 42px;
    border: 1px solid #DEDEDE;
    opacity: 1;
    border-radius: 4px;
}

.gl_dialog_li>.title {
    width: 100px;
    text-align: center;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
    opacity: 1;
    margin-right: 10px;
}

.flex_center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex_center_x {
    display: flex;
    align-items: center;
}

.flex_between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex_wrap_between {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.flex_column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.flex_end {
    display: flex;
    align-items: flex-end;
}
</style>