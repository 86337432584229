<template>
    <div>
        <el-dialog top="5vh" title="编辑团队" custom-class="gl_dialog_wrapper" :visible.sync="dialogEdit" width="60%"
            :before-close="handleClose">
            <div class="gl_tabs"> <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="基本信息" name="first">
                        <el-form :model="ruleFormInfo" status-icon :rules="rules" ref="ruleFormInfo" label-width="100px"
                            class="demo-ruleForm">
                            <el-form-item label="团队名称" prop="name">
                                <el-input placeholder="请输入团队名称" v-model="ruleFormInfo.name"></el-input>
                            </el-form-item>
                            <el-form-item label="成员数量" prop="childNum">
                                <el-input placeholder="请输入成员数量" v-model="ruleFormInfo.childNum"></el-input>
                            </el-form-item>
                            <el-form-item label="分组">
                                <el-select class="input-with" v-model="ruleFormInfo.groupName" placeholder="请选择分组">
                                    <el-option v-for="item in options" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="标签">
                                <el-cascader placeholder="请选择标签" v-model="arrLabels" class="input-with"
                                    :options="optionsCascader" :props="props" clearable></el-cascader>
                            </el-form-item>
                            <el-form-item label="备注">
                                <!-- <el-input v-model="ruleForm.name"></el-input> -->
                                <el-input placeholder="请输入备注" type="textarea" v-model="ruleFormInfo.remark"></el-input>
                            </el-form-item>
                            <el-form-item label="状态">
                                <el-radio-group v-model="ruleFormInfo.status">
                                    <el-radio :label='1'>开启</el-radio>
                                    <el-radio :label='0'>关闭</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item>
                                <div class="form_item_button">
                                    <el-button type="primary" @click="submitFormInfo('ruleFormInfo')">提 交</el-button>
                                </div>
                            </el-form-item>
                        </el-form>
                    </el-tab-pane>
                    <el-tab-pane label="实名认证" name="second">
                        <!-- 未实名 -->
                        <div v-if="auType == 0 || auType == null" class="gl_auth">
                            <div class="title">当前帐号尚未认证</div>
                            <div class="gl_title">请选择以下认证类型</div>
                            <div class="gl_view flex_center_x">
                                <div class="img_view flex_between">
                                    <img src="../../assets/user.png" alt="">
                                    <div class="title">个人认证</div>
                                </div>
                                <div class="info_view flex_between_y">
                                    <div class="info_view_on flex_center_x">
                                        <div class="title flex_center">帐号归属</div>
                                        <div class="text">适用于个人用户，帐号归属于个人</div>
                                    </div>
                                    <div class="info_view_to">重要提示：企业使用的帐号请避免使用个人认证，以免后续人员变动等情况导致帐号管理问题 </div>
                                    <div @click="onClickauType(2)" class="info_view_th  flex_center">立即选择</div>
                                </div>
                            </div>
                            <div class="gl_view flex_center_x">
                                <div class="img_view flex_between">
                                    <img src="../../assets/enterprise.png" alt="">
                                    <div class="title">企业认证</div>
                                </div>
                                <div class="info_view flex_between_y">
                                    <div class="info_view_on flex_center_x">
                                        <div class="title flex_center">帐号归属</div>
                                        <div class="text">适用企业、个体工商户、政府、事业单位、学校、组织等，帐号归属企业</div>
                                    </div>
                                    <div @click="onClickauType(1)" class="info_view_th  flex_center">立即选择</div>
                                </div>
                            </div>
                            <div style="width: 100%;height: 26px;"></div>
                        </div>
                        <!-- 已经选择实名 个人 -->
                        <div v-if="auType == 2">
                            <el-form ref="form" :model="form" label-width="140px">
                                <el-form-item label="认证类型">
                                    <el-input :disabled="true" value="个人认证"></el-input>
                                </el-form-item>
                                <el-row :gutter="24">
                                    <el-col :span="14">
                                        <el-form-item label="姓名">
                                            <el-input placeholder="请输入姓名" v-model="ruleForm.name"></el-input>
                                        </el-form-item>
                                        <el-form-item label="证件号码">
                                            <el-input placeholder="请输入证件号码" v-model="ruleForm.certificateNo"></el-input>
                                        </el-form-item>
                                        <el-form-item label="性别">
                                            <el-select class="input-with input_comm" v-model="ruleForm.sex"
                                                placeholder="请选择性别" clearable>
                                                <el-option v-for="item in optionsGroup" :key="item.value"
                                                    :label="item.label" :value="item.value">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item label="民族">
                                            <el-input placeholder="请输入民族" v-model="ruleForm.nation"></el-input>
                                        </el-form-item>
                                        <el-form-item label="出生日期">
                                            <el-date-picker value-format="yyyy-MM-dd" format="yyyy-MM-dd"
                                                style="width: 100%;" v-model="ruleForm.birth" align="right" type="date"
                                                placeholder="选择出生日期">
                                            </el-date-picker>
                                            <!-- <el-input placeholder="请" v-model="ruleForm.birth"></el-input> -->
                                        </el-form-item>
                                        <el-form-item label="住所">
                                            <el-input placeholder="请输入住所" v-model="ruleForm.address"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="10">
                                        <el-form-item label="证件（正面）" class="flex_item">
                                            <el-upload :disabled="ruleForm.idCardFront ? true : false"
                                                class="avatar-uploader"
                                                action="https://apisgu.chinaucv.com/staffPlatform/ocr/uploadImage"
                                                :show-file-list="false" :on-success="handleAvatarSuccessFront"
                                                :before-upload="beforeAvatarUpload" :headers="uploadHeaders"
                                                accept="image/jpg,image/jpeg,image/png" name="image" :data="{ type: 5 }"
                                                :multiple="false">
                                                <div style="background: #fff;">
                                                    <el-image class="avatar" :zIndex="9999" v-if="ruleForm.idCardFront"
                                                        :src="ruleForm.idCardFront"
                                                        :preview-src-list="[ruleForm.idCardFront]" fit="contain">
                                                    </el-image>
                                                </div>
                                                <i v-if="!ruleForm.idCardFront"
                                                    class="el-icon-plus avatar-uploader-icon"></i>
                                                <div class="avatar_delete" v-if="ruleForm.idCardFront"
                                                    @click.stop="onClickDeleteImageFront()">
                                                    <img style="width:14px;height: 14px;" src="../../assets/error.png"
                                                        alt="">
                                                </div>
                                            </el-upload>
                                        </el-form-item>
                                        <el-form-item label="证件（反面）" class="flex_item">
                                            <el-upload :disabled="ruleForm.idCardBack ? true : false"
                                                class="avatar-uploader"
                                                action="https://apisgu.chinaucv.com/staffPlatform/upload/uploadImage"
                                                :show-file-list="false" :on-success="handleAvatarSuccessBrak"
                                                :before-upload="beforeAvatarUpload" :headers="uploadHeaders"
                                                accept="image/jpg,image/jpeg,image/png" name="image"
                                                :data="{ type: 0, vehicleModelId: 0 }" :multiple="false">
                                                <div style="background: #fff;">
                                                    <el-image class="avatar" :zIndex="9999" v-if="ruleForm.idCardBack"
                                                        :src="ruleForm.idCardBack" :preview-src-list="[ruleForm.idCardBack]"
                                                        fit="contain">
                                                    </el-image>
                                                </div>
                                                <i v-if="!ruleForm.idCardBack"
                                                    class="el-icon-plus avatar-uploader-icon"></i>
                                                <div class="avatar_delete" v-if="ruleForm.idCardBack"
                                                    @click.stop="onClickDeleteImageBack()">
                                                    <img style="width:14px;height: 14px;" src="../../assets/error.png"
                                                        alt="">
                                                </div>
                                            </el-upload>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-form-item>
                                    <div class="form_item_button">
                                        <el-button @click="resetForm('ruleForm')">返回上一步</el-button>
                                        <el-button type="primary" @click="submitForm('ruleForm')">提 交</el-button>
                                    </div>
                                </el-form-item>
                            </el-form>
                        </div>
                        <!-- 已经选择实名 企业 -->
                        <div v-if="auType == 1">
                            <el-form ref="form" :model="ruleForm" label-width="140px">
                                <el-form-item label="认证类型">
                                    <el-input :disabled="true" value="企业认证"></el-input>
                                </el-form-item>
                                <el-row :gutter="24">
                                    <el-col :span="14">
                                        <el-form-item label="企业名称">
                                            <el-input placeholder="请输入企业名称" v-model="ruleForm.name"></el-input>
                                        </el-form-item>
                                        <el-form-item label="统一社会信用代码">
                                            <el-input placeholder="请输入统一社会信用代码" v-model="ruleForm.certificateNo"></el-input>
                                        </el-form-item>
                                        <el-form-item label="法定代表人">
                                            <el-input placeholder="请输入法定代表人" v-model="ruleForm.legalPersonName"></el-input>
                                        </el-form-item>
                                        <el-form-item label="注册资本（万元）">
                                            <el-input placeholder="请输入注册资本" v-model="ruleForm.capital"></el-input>
                                        </el-form-item>
                                        <el-form-item label="成立日期">
                                            <!-- <el-input placeholder="请输入成立日期" v-model="ruleForm.name"></el-input> -->
                                            <el-date-picker value-format="yyyy-MM-dd" format="yyyy-MM-dd"
                                                style="width: 100%;" v-model="ruleForm.foundTime" align="right" type="date"
                                                placeholder="选择成立日期">
                                            </el-date-picker>
                                        </el-form-item>
                                        <el-form-item label="住所">
                                            <el-input placeholder="请输入住所" v-model="ruleForm.residence"></el-input>
                                        </el-form-item>
                                        <el-form-item label="经营范围">
                                            <el-input placeholder="请输入经营范围" v-model="ruleForm.businessScope"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="10">
                                        <el-form-item label="证件（正面）" class="flex_item">
                                            <el-upload :disabled="ruleForm.businessLicense ? true : false"
                                                class="avatar-uploader"
                                                action="https://apisgu.chinaucv.com/staffPlatform/ocr/uploadImage"
                                                :show-file-list="false" :on-success="handleAvatarSuccess"
                                                :before-upload="beforeAvatarUpload" :headers="uploadHeaders"
                                                accept="image/jpg,image/jpeg,image/png" name="image" :data="{ type: 7 }"
                                                :multiple="false">
                                                <div style="background: #fff;">
                                                    <el-image class="avatar" :zIndex="9999" v-if="ruleForm.businessLicense"
                                                        :src="ruleForm.businessLicense"
                                                        :preview-src-list="[ruleForm.businessLicense]" fit="contain">
                                                    </el-image>
                                                </div>
                                                <i v-if="!ruleForm.businessLicense"
                                                    class="el-icon-plus avatar-uploader-icon"></i>
                                                <div class="avatar_delete" v-if="ruleForm.businessLicense"
                                                    @click.stop="onClickDeleteImage()">
                                                    <img style="width:14px;height: 14px;" src="../../assets/error.png"
                                                        alt="">
                                                </div>
                                            </el-upload>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-form-item>
                                    <div class="form_item_button">
                                        <el-button @click="resetForm('form')">返回上一步</el-button>
                                        <el-button type="primary" @click="submitForm('form')">提 交</el-button>
                                    </div>
                                </el-form-item>
                            </el-form>
                        </div>
                    </el-tab-pane>
                </el-tabs></div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: ["dialogEdit", "dialogEditData"],
    data() {
        var validatePhone = (rule, value, callback) => {
            var reg = /^\d+$/;
            if (value === '') {
                callback(new Error('请输入成员数量'));
            } else if (!reg.test(value)) {
                callback(new Error('成员数量仅支持整数哦'));
            } else {
                callback();
            }
        };
        return {
            activeName: "first",
            ruleFormInfo: {},
            ruleForm: {
                name: "", //企业  个人的名称
                certificateNo: "",//企业是统一社会信用代码，个人则是身份证号
                legalPersonName: "", //法人名称
                legalPersonIdNo: "", // 法人证件号
                residence: "",//住所
                period: "",//营业日期范围原始日期
                capital: "", // 注册资本(万元)
                foundTime: "", //成立日期
                startTime: "", //营业起始日期
                endTime: "",//营业终止日期
                businessScope: "",//经营范围

                sex: "", //性别 (1:男，2: 女)
                nation: "", //民族
                birth: "", //出生日期
                address: "", //住址
                startDate: "",//有效期起始日期
                validDate: "",//有效期截至时间为空长期有效
                state: "",//1:生效，2:失效
                businessLicense: "",//营业执照证件照地址 (oss)
                idCardFront: "", //身份证照正面地址 oss)
            },
            rules: {
                name: [
                    { required: true, message: '请输入团队名称', trigger: 'blur' },
                    { min: 1, max: 20, message: '长度在 2 0 个字符以内', trigger: 'blur' }
                ],
                childNum: [
                    { required: true, validator: validatePhone, trigger: 'blur' }
                ],
            },
            props: { multiple: true },
            optionsCascader: [{
                value: '食品',
                label: '食品',
                children: [{
                    value: '包子',
                    label: '包子'
                }, {
                    value: '大米',
                    label: '大米',
                }, {
                    value: '韭菜',
                    label: '韭菜',
                }]
            }, {
                value: '木材',
                label: '木材',
                children: [{
                    value: '桌子',
                    label: '桌子'
                }, {
                    value: '椅子',
                    label: '椅子',
                }, {
                    value: '板凳',
                    label: '板凳',
                }]
            }],
            // 分组列表
            options: [{
                value: '金融机构',
                label: '金融机构'
            }, {
                value: '主机厂',
                label: '主机厂'
            }, {
                value: '物流企业',
                label: '物流企业'
            }, {
                value: '政府机构',
                label: '政府机构'
            }, {
                value: '经销商',
                label: '经销商'
            }],
            form: {},
            uploadHeaders: {},
            auType: 0,
            optionsGroup: [{
                value: 1,
                label: '男'
            }, {
                value: 2,
                label: '女'
            }],
            loading: null,
            arrLabels: [],
            loading: null,
        }
    },
    created() {
        // 获取token
        let token = sessionStorage.getItem("token");
        this.uploadHeaders = { token };
    },
    mouted() {

    },
    methods: {
        handleClose() {
            this.$emit('editingClose', false);
        },
        handleClick() { },

        // 上传图片 身份证反面
        handleAvatarSuccessBrak(file) {
            this.loading.close();
            this.$set(this.ruleForm, "idCardBack", file.content.urls[0]);
        },
        // 删除图片 身份证反面
        onClickDeleteImageBack() {
            this.$set(this.ruleForm, "idCardBack", '');
        },
        // 上传 身份证 正面
        handleAvatarSuccessFront(file) {
            this.loading.close();
            let res = JSON.parse(file.data.result.ocrResult)
            let ruleForm = this.ruleForm;
            ruleForm.sex = res.Sex == '女' ? 2 : 1;
            ruleForm.name = res.Name;
            ruleForm.nation = res.Nation;
            ruleForm.certificateNo = res.IdNum;
            if (res.Birth) {
                let tt = res.Birth.split("/"); // 使用逗号作为分隔符
                tt[1] = tt[1] < 10 ? `0${tt[1]}` : `${tt[1]}`
                tt[2] = tt[2] < 10 ? `0${tt[2]}` : `${tt[2]}`
                ruleForm.birth = tt.join('-');
            }
            ruleForm.address = res.Address;
            ruleForm.idCardFront = file.data.result.imageUrl;
            this.ruleForm = ruleForm;
        },
        // 删除图片 身份证正面
        onClickDeleteImageFront() {
            this.$set(this.ruleForm, "idCardFront", '');
        },
        // 上传图片 营业执照
        handleAvatarSuccess(file) {
            this.loading.close();
            let res = JSON.parse(file.data.result.ocrResult)
            let ruleForm = this.ruleForm;
            ruleForm.name = res.Name;
            ruleForm.certificateNo = res.RegNum;
            ruleForm.legalPersonName = res.Person;
            ruleForm.capital = this.chineseAmountToNumber(res.Capital);
            // console.log(res.Capital.toLowerCase())
            ruleForm.residence = res.Address;
            ruleForm.businessScope = res.Business;
            if (res.SetDate) {
                let s = res.SetDate.replace(/[\u4e00-\u9fa5]+/g, '-');
                let t = s.slice(0, -1);
                let tt = t.split("-"); // 使用逗号作为分隔符
                tt[1] = tt[1] < 10 ? `0${tt[1]}` : `${tt[1]}`
                tt[2] = tt[2] < 10 ? `0${tt[2]}` : `${tt[2]}`
                ruleForm.foundTime = tt.join('-');
            }
            ruleForm.businessLicense = file.data.result.imageUrl;
            this.ruleForm = ruleForm;
        },
        // 上传图片 打开加载效果
        beforeAvatarUpload() {
            this.loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },
        //点击企业认证/个人认证
        onClickauType(type) {
            console.log(type)
            // this.$set(this.auType, type);
            this.auType = type;

        },
        // 重置 认证表单
        resetForm() {
            this.ruleForm = {
                name: "", //企业  个人的名称
                certificateNo: "",//企业是统一社会信用代码，个人则是身份证号
                legalPersonName: "", //法人名称
                legalPersonIdNo: "", // 法人证件号
                residence: "",//住所
                period: "",//营业日期范围原始日期
                capital: "", // 注册资本(万元)
                foundTime: "", //成立日期
                startTime: "", //营业起始日期
                endTime: "",//营业终止日期
                businessScope: "",//经营范围

                sex: "", //性别 (1:男，2: 女)
                nation: "", //民族
                birth: "", //出生日期
                address: "", //住址
                startDate: "",//有效期起始日期
                validDate: "",//有效期截至时间为空长期有效
                state: "",//1:生效，2:失效
                businessLicense: "",//营业执照证件照地址 (oss)
                idCardFront: "", //身份证照正面地址 oss)
            }
            this.auType = 0;
        },
        // 提交 基本信息
        submitFormInfo(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    this.loading = this.$loading({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    if (this.arrLabels.length == 0) {
                        this.ruleFormInfo.labels = ''
                    } else {
                        let labels = [];
                        for (var a = 0; a < this.arrLabels.length; a++) {
                            labels.push(this.arrLabels[a][1])
                            this.ruleFormInfo.labels = labels.join();
                        }
                    }
                    this.ruleFormInfo.id = this.dialogEditData.id;
                    var { data: res } = await this.$http({
                        url: "/member/update",
                        method: "post",
                        data: this.ruleFormInfo
                    });
                    if (res.resultStates != 0) return this.$message.error(res.message)
                    this.loading.close();
                    this.$message.success("修改成功")
                    this.$emit('submitForm', true);
                } else {
                    return false;
                }
            })
        },
        // 提交 认证 
        async submitForm() {
            this.loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            console.log(this.dialogEditData)
            this.ruleForm.type = this.auType;
            this.ruleForm.memberId = this.dialogEditData.id;
            // 
            const { data: res } = await this.$http({
                url: "/member/saveAuthentication",
                method: "post",
                data: this.ruleForm
            });
            if (res.resultStates != 0) return this.$message.error(res.message)
            this.loading.close();
            this.$message.success("修改成功")
            this.$emit('submitForm', true);
        },
        // 获取认证 信息
        async getInfo(data) {
            const { data: res } = await this.$http({
                url: "/member/getAuthentication",
                method: "post",
                data: { memberId: data.id }
            });
            this.ruleForm = res.content
        },
        // 获取基本信息
        async getInfoAsync(data) {
            this.loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            const { data: res } = await this.$http({
                url: "/member/getDetail",
                method: "post",
                data: { memberId: data.id }
            });
            let arrLabels = [];
            if (res.content.labels) {
                let arr = res.content.labels.split(",");
                let optionsCascader = this.optionsCascader
                for (var a = 0; a < arr.length; a++) {
                    optionsCascader.forEach((item) => {
                        item.children.forEach((ite) => {
                            if (ite.value == arr[a]) {
                                arrLabels.push([item.value, ite.value])
                            }
                        })
                    })
                }
                this.arrLabels = arrLabels;
            }
            let ruleFormInfo = {};
            ruleFormInfo.childNum = res.content.childNum
            ruleFormInfo.groupName = res.content.groupName
            ruleFormInfo.name = res.content.name
            ruleFormInfo.remark = res.content.remark
            ruleFormInfo.status = res.content.status
            ruleFormInfo.labels = res.content.labels
            this.ruleFormInfo = ruleFormInfo;
            this.loading.close();
        },
        // 进入执行
        callMethod(data, index = false) {
            this.auType = data.type || 0;
            console.log(index)
            if (index) {
                this.activeName = "second";
            } else {
                this.activeName = "first";
            }
            if (data.type != 0) {
                this.getInfo(data);
            }
            this.getInfoAsync(data);
        },



        //数字大写转换小写
        chineseAmountToNumber(chineseAmount) {
            // 定义中文数字和对应的阿拉伯数字的映射关系
            const chineseNumbers = {
                '零': 0,
                '壹': 1,
                '贰': 2,
                '叁': 3,
                '肆': 4,
                '伍': 5,
                '陆': 6,
                '柒': 7,
                '捌': 8,
                '玖': 9,
            };

            // 定义单位和对应的阿拉伯数字的映射关系
            const chineseUnits = {
                '拾': 10,
                '佰': 100,
                '仟': 1000,
                '万': 10000,
                '亿': 100000000,
                '兆': 1000000000000,
                // 在这里可以添加更多单位，如：京、垓、秭等等
            };

            const stack = []; // 使用栈来辅助处理

            for (let i = 0; i < chineseAmount.length; i++) {
                const char = chineseAmount.charAt(i);
                if (chineseNumbers[char] !== undefined) {
                    stack.push(chineseNumbers[char]);
                } else if (chineseUnits[char] !== undefined) {
                    const currentUnit = chineseUnits[char];
                    let currentNumber = 0;
                    while (stack.length > 0 && stack[stack.length - 1] < currentUnit) {
                        // 处理“万”以上的单位，如亿、兆等
                        currentNumber += stack.pop();
                    }
                    if (currentNumber === 0) {
                        // 处理单位后面没有数字的情况，如“一亿”
                        currentNumber = 1;
                    }
                    stack.push(currentNumber * currentUnit);
                }
            }

            // 将栈中剩余的数字累加得到最终结果
            let totalAmount = 0;
            while (stack.length > 0) {
                totalAmount += stack.pop();
            }

            // 转换为万元并保留两位小数
            const result = (totalAmount / 10000).toFixed(2);
            return parseFloat(result);
        }
    },
}
</script>
<style scoped >
.gl_auth {
    width: 100%;
}

.gl_auth>.title {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    opacity: 1;
}

.gl_auth>.gl_title {
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
    opacity: 1;
    margin-top: 37px;
}

.gl_auth>.gl_view {
    width: 100%;
    margin-top: 17px;
}

.gl_view>.img_view {
    width: 190px;
    height: 124px;
    border-radius: 4px;
    border: 1px solid #CCCCCC;
    box-sizing: border-box;
}

.img_view>img {
    width: 46px;
    height: 42px;
    margin-bottom: 10px;
}

.avatar-uploader {
    width: 190px;
    height: 124px;
    position: relative;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 190px;
    height: 124px;
    line-height: 124px;
    text-align: center;
    border: 1px solid #CCCCCC;
    overflow: hidden;
}

.avatar {
    width: 190px;
    height: 124px;
    display: block;
}

.avatar_delete {
    position: absolute;
    top: -10px;
    right: 0;
}

.img_view>.title {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
}

.info_view_on>.title {
    width: 64px;
    height: 22px;
    border: 1px solid #DE0101;
    opacity: 1;
    border-radius: 4px;
    font-size: 13px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #DE0101;
    opacity: 1;
    margin-right: 10px;
}

.info_view_on>.text {
    font-size: 13px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 18px;
    color: #333333;
    opacity: 1;
}

.info_view_to {
    font-size: 13px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #DE0101;
    opacity: 1;
    margin-top: -20px;
}

.info_view_th {
    width: 64px;
    height: 26px;
    background: rgba(53, 157, 250);
    opacity: 1;
    border-radius: 4px;
    font-size: 13px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    cursor: pointer;
}

.info_view {
    flex: 1;
    height: 124px;
    margin-left: 20px;
}

.input-with {
    width: 100%;
    font-family: PingFang SC;
    font-weight: 400;
    opacity: 1;
}

.form_item_button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.flex_item {
    display: flex;
    justify-content: flex-end;
    margin-right: 2px;
}



.flex_center_x {
    display: flex;
    align-items: center;
}

.flex_center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex_between_y {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.flex_between {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.gl_dialog_wrapper {
    padding: 0;
}

.el-dialog__wrapper .el-dialog__body {
    padding-bottom: 2px;
}
</style>
<style>
.flex_item .el-form-item__content {
    margin-left: 0 !important;
}

.el-dialog__wrapper .el-dialog__body {
    padding-bottom: 2px;
}
</style>